<template>
  <div class="page page-Home" :class="{ loaded: isLoaded }">
    <div
      v-if="!isMobile"
      class="background-video-image"
      :style="bgImgStyle(videoBackground)"
    >
      &nbsp;
    </div>
    <transition
      v-if="$store.state.home.showVideo && !isMobile"
      name="fade"
      mode="out-in"
    >
      <div
        class="background-video"
        v-if="$store.state.home.showVideo && !isMobile"
      >
        <video
          class="video-player tv"
          autoplay
          muted
          ref="video"
          @oncanplay="onVideoLoaded()"
        >
          <source :src="videoSource" type="video/webm" />
          <source :src="videoSource" type="video/mp4" />
        </video>
      </div>
    </transition>
    <div class="content">
      <div
        v-if="isMobile"
        :style="bgImgStyle(videoBackground)"
        class="mobileHomeBg m50"
      ></div>

      <h1 class="m100" v-if="!isMobile">
        {{ $t('lang.home.mainText') }}<br />{{ $t('lang.home.subText') }}
      </h1>
      <h1 class="m100" v-if="isMobile">
        {{ $t('lang.home.mainText') }}<br />{{ $t('lang.home.subText') }}
      </h1>

      <download v-if="isMobile" class="m75"></download>
    </div>
  </div>
</template>

<script>
import { loader } from '../mixins/loader';
import { isMobile } from '../mixins/isMobile';
import { functions } from '../mixins/functions';
import Download from './includes/Download';

import ENV from '@/environment';

export default {
  name: 'Home',
  mixins: [loader, isMobile, functions],
  components: { Download },

  data() {
    return {
      pageData: { page: 'Home', isDownloadRight: true },
      videoBackground:
        ENV.REGION === 'by'
          ? {
              '1x': '/img/video-background-by.png',
              '2x': '/img/video-background-by.png',
            }
          : {
              '1x': '/img/video-background.png',
              '2x': '/img/video-background@2x.png',
            },
      videoSource: ENV.REGION === 'by' ? 'video/main_by.mp4' : 'video/main.mp4',
      videoLoaded: false,
      show: true,
      ENV,
    };
  },

  deactivated() {
    this.isLoaded = false;
  },

  activated() {
    this.$store.commit('ui/loaderON');
    this.onActivated();
  },

  watch: {
    isLoaded(val) {
      if (val === true && this.$store.state.home.firstLoad) {
        if (this.$refs.video) {
          this.$refs.video.onended = () => this.turnOffVideo();
        }
        this.$store.commit('home/onFirstLoad');
      }
    },
  },
  methods: {
    onActivated() {
      if (this.imagesLoaded && this.videoLoaded) {
        this.pageLoaded();
      } else {
        this.loadImages([this.videoBackground['1x']]);
      }
    },
    turnOffVideo() {
      this.$store.commit('home/hideVideo');
    },
    onVideoLoaded() {
      this.videoLoaded = true;
      this.onActivated();
    },
  },
};
</script>

<style>
.video-player {
  width: 100%;
  min-width: 1340px;
  position: relative;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1.3s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
